.fixed-dimensions {
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin-bottom: 16px;
  margin-top: 16px;
}

.fixed-dimensions:last-child {
  margin-bottom: 0;
}

.marquee-container {
  margin-bottom: 16px;
  margin-top: 16px;
  width: 100%;
  overflow: hidden;
  background: #027bbf;
  height: 35px;
  position: relative;
  padding: 5px;
}

.marquee-content {
  display: inline-block;
  white-space: nowrap;
  position: absolute;
  top: 5px;
  animation: marquee 10s linear infinite;
}

.marquee-content strong {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  color: white;
}

@keyframes marquee {
  0% {
    left: 100%;
  }
  100% {
    left: -100%;
  }
}
