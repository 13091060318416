/* Facility.css */

@media (max-width: 768px) {
  #facility-table {
    font-size: 14px;
    width: 100%;
    table-layout: auto;
    overflow-x: auto;
    display: block;
  }

  .table-header,
  .table-cell {
    padding: 8px;
  }
}
